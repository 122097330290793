/**
 * PageHomeKulturama
 */

import React from 'react';
import { basePageWrap } from 'Layouts/BasePage';
import { isSet } from 'utils/is';
import { useStyle } from 'Themes/theme';
import HeroSlider from 'Components/HeroSlider';
import SubjectList from 'Components/SubjectList';
import CalendarList from 'Components/CalendarList';
import Billboard from 'Components/Billboard';
import SliderCardNews from 'Components/SliderCardNews';
import SliderCardArticle from 'Components/SliderCardArticle';
import Notification from 'Components/Notification';

const styles = {
};

const PageHomeKulturama = ({
    billboard,
    sliderCardArticle,
    calendarTitle,
    calendarList,
    hero,
    subSection,
    notification,
    newsList,
}) => {
    const s = useStyle(styles);

    return (
        <div className={s['PageHomeKulturama']}>
            <HeroSlider
                {...hero}
                modifier="PageHome"
            />

            {notification &&
                <Notification {...notification} />
            }
            {subSection.items &&
                <SubjectList
                    {...subSection}
                />
            }
            {(Object.keys(calendarList.items).length > 0) &&
                <CalendarList
                    {...calendarList}
                    calendarTitle={calendarTitle}
                    modifier='Home'
                    cardmodifiers={['Red', 'Pink', 'Yellow', 'Purple']}
                />
            }
            {billboard.title &&
                <Billboard
                    title={billboard.title}
                    link={billboard.link}
                    image={billboard.image}
                />
            }

            {sliderCardArticle.items &&
                <SliderCardArticle {...sliderCardArticle} />
            }

            {isSet(newsList) && isSet(newsList.items) &&
                <SliderCardNews {...newsList} hasBg={true} />
            }
        </div>
    );
};

PageHomeKulturama.defaultProps = {
    title:'',
    preamble: '',
    subSection: {},
    billboard: {},
    calendarTitle: '',
    calendarList: {
        items: []
    },
    newsList: {
        items: []
    },
    sliderCardArticle: {},
    hero: {},
    notification: {}
};

export default basePageWrap(PageHomeKulturama);
